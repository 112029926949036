<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app clipped-left>
            <v-toolbar-title class="text-overline" style="font-size: 16px!important;">
                <router-link :to="{ name: 'front' }" style="text-decoration: none;">
                    <!-- <span class="blue--text text--darken-1">Liberty</span><span class="blue--text text--lighten-2">DNS</span> -->
                    <v-img alt="EtherLink" src="@/assets/logo/etherlink/EtherLink_Logotype_Light_180x48.png" class="mr-1 float-left" width="135" height="36" fit="inside"></v-img>
                </router-link>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="blue"
            ></v-progress-linear>
            <v-spacer></v-spacer>
            <template v-if="isAuthenticated && account">
                <v-btn icon v-bind="attrs" v-on="on" class="blue--text" disabled>
                    <font-awesome-icon :icon="['fas', 'folder-open']" class="blue--text" fixed-width/>
                </v-btn>
                <!-- <v-menu offset-y right open-on-click open-on-hover close-delay="100">
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on" class="blue--text">
                            <font-awesome-icon :icon="['fas', 'folder-open']" class="blue--text" fixed-width/>
                        </v-btn>
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item :to="{ name: 'account-search-file', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'file']" class="blue--text" fixed-width/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Documents
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :to="{ name: 'account-search-contact', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'address-book']" class="blue--text" fixed-width/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Contacts
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item :to="{ name: 'account-search-user', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'user-friends']" class="blue--text" fixed-width/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Users
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item :to="{ name: 'account-search-signature-document', params: { brandprofile: this.$route.params.brandprofile, accountId: this.$route.params.accountId }, query: { t: Date.now() } }">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'file-signature']" class="blue--text" fixed-width/>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Signature Requests
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu> -->
                <TextButton class="text-headline" style="font-size: 1.1em; line-height: 1.1em;" @click="navigateToAccountDashboard">
                {{ account.name }}
                </TextButton>
            </template>
            <!-- <v-menu offset-y left open-on-click open-on-hover close-delay="100" v-if="isAuthenticated && $route.params.accountId">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="blue--text text--darken-2">
                        <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <! -- <v-list-item :to="{ name: 'account-create-product', params: { accountId: $route.params.accountId } }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'box']" class="blue--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                New Product
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -- >
                    <! -- <v-list-item :to="{ name: 'account-create-customer', params: { accountId: $route.params.accountId } }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" class="blue--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                New Customer
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -- >
                    <! -- TODO: new customer, new order, new invoice, ... -- >
                </v-list>
            </v-menu> -->
            <!-- <v-btn icon color="blue darken-2" @click="search">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
            </v-btn> -->
            <v-menu v-model="helpMenu" offset-y open-on-click open-on-hover close-delay="100">
                <template #activator="{ on, attrs }">
                    <v-btn icon color="blue darken-2" v-bind="attrs" v-on="on">
                        <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                        <font-awesome-icon :icon="['fas','question']" fixed-width/>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        Questions or comments?
                        <a href="https://cryptium.com/contact/" target="_blank">Contact us!</a>
                    </v-card-text>
                </v-card>
            </v-menu>
            <!-- <div v-if="!isAuthenticated">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs slider-color="blue" right>
                        <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                        <v-tab to="/about">About</v-tab>
                        <v-tab to="/login">Login</v-tab>
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y open-on-click open-on-hover close-delay="100">
                        <template #activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on">
                                <font-awesome-icon right class="blue--text" :icon="['fas', 'bars']" fixed-width/>
                            </v-btn>
                        </template>
                        <v-tabs vertical slider-color="blue">
                            <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                            <v-tab to="/about">About</v-tab>
                            <v-tab to="/login">Login</v-tab>
                        </v-tabs >
                    </v-menu>
                </div>
            </div> -->
            <v-btn outlined color="blue darken-2" v-if="!isAuthenticated" @click="login">
                <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                <!-- <font-awesome-icon :icon="['fas','question']" fixed-width/> -->
                Sign in
            </v-btn>
            <v-menu offset-y left open-on-click open-on-hover close-delay="100" v-if="isAuthenticated">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="blue--text text--darken-2">
                        <Avatar :attr="mainProfile" :size="28"/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item v-if="user">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ user.display_name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ user.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'dashboard' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'chart-line']" class="blue--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Dashboard
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'profile' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'user']" class="blue--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Profile
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'user-account-list' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" class="blue--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Accounts
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'id-badge']" class="blue--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Service Admin
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item @click="onLogout">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'shield-alt']" class="blue--text text--darken-2" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Logout
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <div>
                        <v-tabs vertical slider-color="blue darken-2" class="menutabs">
                            <v-tab :to="{ name: 'dashboard' }" v-show="false"></v-tab>
                            <v-tab :to="{ name: 'profile' }">Profile</v-tab>
                            <v-tab :to="{ name: 'user-account-list' }">Accounts</v-tab>
                            <v-tab :to="{ name: 'dashboard' }">Dashboard</v-tab>
                            <v-tab :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">Service admin</v-tab>
                            <v-divider></v-divider>
                            <v-tab @click="onLogout">Logout</v-tab>
                        </v-tabs>
                    </div> -->
                </v-list>
            </v-menu>
        </v-app-bar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';
import TextButton from '@/components/TextButton.vue';

export default {
    components: {
        Avatar,
        TextButton,
    },

    data: () => ({
        helpMenu: false,
    }),

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            account: (state) => state.account,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
        isPermitServiceAdmin() {
            return this.isAuthenticated && Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },

    methods: {
        login() {
            const { fullPath } = this.$router.currentRoute;
            if (this.$route.name !== 'login') {
                this.$router.push({ path: '/login', query: { next: fullPath } });
            }
        },
        async onLogout() {
            console.log('onLogout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                this.$router.push({ name: 'front' });
            }
        },
        search() {
            if (this.$route.name === 'search') {
                // reset the search input to empty string
                this.$bus.$emit('search', '');
            } else {
                this.$router.push({ name: 'search' /* , query: { q: this.searchQuery } */ });
            }
        },
        navigateToAccountDashboard() {
            this.$router.push({ name: 'account-dashboard', params: { accountId: this.account.id }, query: { t: Date.now() } });
        },
    },

    mounted() {
        console.log(`navbar route params ${JSON.stringify(this.$route.params)}`);
        if (this.$route.params.realmId) {
            console.log(`navabar realm ${this.$route.params.realmId}`);
        } else {
            console.log('navbar no realm');
        }
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
