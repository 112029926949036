<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4 noprint">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="account">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="mt-8">
                        <v-app-bar color="blue darken-2" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>Account settings</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-0">BrandProfile</p>
                            <p class="mb-0 pb-0">
                                You can link your account with a BrandProfile to show your logo and custom brand color palette on your customer service website.
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-brandprofile', params: { accountId: this.$route.params.accountId } }">Manage BrandProfile</router-link>
                            </p>
                            <!-- <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-hostname', params: { accountId: this.$route.params.accountId } }">Custom Hostname</router-link>
                            </p> -->

                            <p class="text-overline mb-0 mt-8">Account Users</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-user', params: { accountId: this.$route.params.accountId } }">See all users</router-link>
                                <span v-if="accountSummary" class="ml-2">({{ accountSummary.user_count }})</span>
                            </p>

                            <p class="text-overline mb-0 mt-8">API Tokens</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-client', params: { accountId: this.$route.params.accountId } }">See all clients</router-link>
                                <span v-if="accountSummary" class="ml-2">({{ accountSummary.client_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-client-token', params: { accountId: this.$route.params.accountId } }">See all API tokens</router-link>
                                <span v-if="accountSummary" class="ml-2">({{ accountSummary.client_token_count }})</span>
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">Stripe</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-edit-stripe', params: { accountId: this.$route.params.accountId } }">Manage Stripe Settings</router-link>
                            </p> -->

                            <!-- <template v-if="accountSummary && (accountSummary.domain_dispute_claimant_count > 0 || accountSummary.domain_dispute_defendant_count > 0)">
                            <p class="text-overline mb-0 mt-8">Disputes</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain-dispute', params: { accountId: this.$route.params.accountId }, query: { role: 'claimant' } }">See all disputes for a domain name</router-link>
                                <span v-if="accountSummary" class="ml-2">({{ accountSummary.domain_dispute_claimant_count }})</span>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-search-domain-dispute', params: { accountId: this.$route.params.accountId }, query: { role: 'defendant' } }">See all disputes against you</router-link>
                                <span v-if="accountSummary" class="ml-2">({{ accountSummary.domain_dispute_defendant_count }})</span>
                            </p>
                            </template> -->

                            <!-- <p class="text-overline mb-0 mt-8">Customer Website</p>
                            <p class="mb-0 pb-0" v-if="accountSummary.customer_website_url">
                                <a :href="accountSummary.customer_website_url" target="_blank">Customer Website URL</a>
                            </p>
                            <p class="mb-0 pb-0" v-if="!accountSummary.customer_website_url">
                                Your customer website URL is not configured. Set up a BrandProfile to create your customer website.
                            </p> -->

                            <!-- <p class="text-overline mb-0 mt-8">Tools</p> -->
                            <!-- <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-view-nameservers', params: { accountId: this.$route.params.accountId } }">Check nameservers for a domain</router-link>
                            </p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-view-whois', params: { accountId: this.$route.params.accountId } }">Check whois for a domain</router-link>
                            </p> -->
                            <!-- <AccountViewList :list="list"/> -->
                            <!-- <RealmList @selected="onSelectRealm" create class="mt-12"/> -->
                            <p class="text-overline mb-0 mt-8">Other</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'account-delete', params: { accountId: this.$route.params.accountId } }">How to delete this account</router-link>
                            </p>

                            <!-- <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="blue--text"/></p>
                            <p class="mb-0 pb-0">
                            </p>
                            </template> -->

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import UserList from '@/components/account-dashboard/UserList.vue';

export default {
    components: {
        // UserList,
    },
    data: () => ({
        account: null,
        accountSummary: null,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        name() {
            return this.account?.name ?? 'Unknown';
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
    },
    watch: {
        focus() {
            this.loadAccount();
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                    this.loadAccountSummary();
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadAccountSummary() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccountSummary: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.check({ item: 'summary' });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.accountSummary = response; // {"domain_count":2,"domain_verification_count":0,"dynamic_dns_count":0,"user_count":1,"domain_dispute_defendant":0,"domain_dispute_claimant":0,"dns_record_count":4}
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account report', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccountSummary: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
