<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link>
                    <template v-if="account">
                    &gt; <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.accountId } }">{{ account.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS records</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card tile elevation="4" class="pa-0">
                        <v-app-bar color="blue darken-2" dark flat dense>
                            <v-app-bar-title>Create Product</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>

                        <v-card-text>
                            <p>Let's start with the product name and type. You can change these later.</p>
                            <!-- <v-btn style="background-color: blue darken-2; color: #ffffff;" elevation="2" @click="createNewItemDialog = true">
                                Create
                            </v-btn> -->
                        </v-card-text>

                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newProductName" label="Product name" hint="The product name. This will be displayed to customers." ref="newProductNameInput"></v-text-field>
                            <!-- <v-select v-model="newProductType" :items="productTypeChoices" label="Product type"/> -->
                            <v-list v-if="productTypeList.length > 0">
                                <v-list-item-group v-model="newProductTypeIndex" color="blue darken-2">
                                <v-list-item v-for="(item, idx) in productTypeList" :key="idx">
                                    <v-list-item-icon>
                                        <font-awesome-icon :icon="item.icon" fixed-width/>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.label }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>

                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="blue white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <!-- <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn> -->
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        account: null,
        productTypeList: [
            { label: 'Clothing', value: 'clothing', icon: ['fas', 'tshirt'] },
            { label: 'Merchandise', value: 'merchandise', icon: ['fas', 'box'] },
            { label: 'Donation', value: 'donation', icon: ['fas', 'box-heart'] },
            { label: 'Download', value: 'download', icon: ['fas', 'download'] },
            { label: 'Membership', value: 'membership', icon: ['fas', 'id-badge'] },
            { label: 'Subscription', value: 'subscription', icon: ['fas', 'file-invoice-dollar'] },
            { label: 'Software as a Service', value: 'saas', icon: ['fas', 'code'] },
            { label: 'Ticket', value: 'ticket', icon: ['fas', 'ticket-alt'] },
            // { label: 'Software as a Service - Ticket', value: 'saas-ticket', icon: ['fas', 'file-invoice-dollar'] },
        ],

        submitFormTimestamp: null,

        newProductName: null,
        newProductTypeIndex: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            productTypeChoices: (state) => state.productTypeChoices,
        }),
        isNewItemFormComplete() {
            return typeof this.newProductName === 'string' && this.newProductName.length > 0 && typeof this.newProductType === 'string' && this.newProductType.length > 0;
        },
        newProductType() {
            if (!Number.isInteger(this.newProductTypeIndex) || !Array.isArray(this.productTypeList)) {
                return null;
            }
            if (this.newProductTypeIndex < 0 || this.newProductTypeIndex >= this.productTypeList.length) {
                return null;
            }
            return this.productTypeList[this.newProductTypeIndex].value;
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    name: this.newProductName,
                    type: this.newProductType,
                };
                const response = await this.$client.account(this.$route.params.accountId).product.create(item);
                if (response?.isCreated && response.id) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.$router.push({ name: 'account-edit-product', params: { accountId: this.$route.params.accountId }, query: { id: response.id, published: 'false' } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
