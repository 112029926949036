<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { realm: this.$route.params.realmId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dashboard</h1>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="blue darken-2" dark flat dense>
                            <v-app-bar-title>Dashboard</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-btn icon @click="openCreateAccountDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-5">Profile</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile' }">My profile</router-link>
                            </p>
                            <p class="text-overline mb-0 mt-10">Accounts</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'user-account-list' }">Manage accounts</router-link>
                            </p>
                            <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="blue--text"/></p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'service-admin' }">Manage EtherLink</router-link>
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';

export default {
    data: () => ({
        snackbarRememberDevice: false,
        snackbarLockTimeUpdated: false,
        snackbarAccountDeleteFailed: false,
        dialogDeleteAccount: false,
        // nameRules: [
        //     (v) => (typeof v === 'string' && v.length >= 1) || 'Must not be empty',
        // ],
        nameRules: [
            (v) => isValidName(v) || 'Enter your name',
        ],
        emailRules: [
            (v) => !v || isValidEmail(v) || 'Enter your email address',
        ],
        phoneRules: [
            (v) => !v || isValidPhone(v) || 'Enter your phone number',
        ],
        snackbarIdentityNameUpdated: false,
        snackbarIdentityEmailUpdated: false,
        dialogEditDisplayName: false,
        editableDisplayName: null,
        editableEmail: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        displayName() {
            if (this.user.info && this.user.info.displayName) {
                return this.user.info.displayName;
            }
            return '(unknown)';
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isEditDisplayNameFormComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.info.displayName;
        },
        isIdentityNameComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.name;
        },
        isIdentityEmailComplete() {
            return this.user
            && this.editableEmail
            && this.editableEmail !== this.user.email;
        },
    },
    watch: {
        dialogEditDisplayName(display) {
            if (display) {
                this.editableDisplayName = this.user.info.displayName;
            }
        },
        email(val) {
            this.editableEmail = val;
        },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
        user(newValue) {
            if (newValue !== null && newValue.loginfront_id === null) {
                this.checkUserLoginFront();
            }
        },
    },
    methods: {
        async init() {
            if (this.user === null || this.user?.id === null) {
                await this.$store.dispatch('loadUser');
            }
            if (this.user?.loginfront_alias === null) {
                await this.checkUserLoginFront();
            }
        },
        async editDisplayName() {
            // try {
            //     this.$store.commit('loading', { editDisplayName: true });
            const isEdited = await this.$store.dispatch('editCurrentUser', { displayName: this.editableDisplayName });
            // this.name = this.editableDisplayName;
            // this.snackbarIdentityNameUpdated = true;
            if (isEdited) {
                this.dialogEditDisplayName = false;
                this.$bus.$emit('snackbar', { type: 'success', message: 'Updated name' });
            } else {
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            }
            // } catch (err) {
            //     console.error('editDisplayName failed', err);
            //     this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            // } finally {
            //     this.$store.commit('loading', { editDisplayName: false });
            // }
        },
        // editEmail() {
        //     this.email = this.editableEmail;
        //     this.snackbarIdentityEmailUpdated = true;
        // },
        async deleteAccount() {
            this.$store.commit('loading', { deleteAccount: true });
            const response = await this.$client.user(this.user.id).user.delete();
            if (response.isDeleted) {
                await this.$store.dispatch('logout');
                this.$router.push('/');
                console.log('account.vue: deleteAccount success: %o', response);
            } else {
                console.log('account.vue: deleteAccount failed: %o', response);
                this.snackbarAccountDeleteFailed = true;
            }
            this.closeDialogDeleteAccount();
            this.$store.commit('loading', { deleteAccount: false });
        },
        closeDialogDeleteAccount() {
            this.dialogDeleteAccount = false;
        },
        async checkUserLoginFront() {
            try {
                this.$store.commit('loading', { checkUserLoginFront: true });
                const response = await this.$client.user(this.user.id).user.check({ item: 'loginfront' });
                if (response.loginfront_alias) {
                    console.log('checkUserLoginFront success: %o', response);
                } else {
                    console.log('checkUserLoginFront failed: %o', response);
                }
            } catch (err) {
                console.error('checkUserLoginFront failed', err);
            } finally {
                this.$store.commit('loading', { checkUserLoginFront: false });
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
