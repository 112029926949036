<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <!-- TODO: breadcrumbs here should be based on client_state nextRoute -->
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'service-admin' }">Service Admin</router-link>
                    <!-- <router-link :to="{ name: 'account-list', params: { product: this.$route.params.productId } }">Accounts</router-link> &gt; -->
                    <!-- <router-link :to="{ name: 'account-dashboard', params: { product: this.$route.params.productId } }">Account</router-link> --> <!-- TODO: missing account id ... -->
                    <!-- <router-link :to="{ name: 'product-dashboard', params: { product: this.$route.params.productId } }">Product</router-link> -->
                </p>
                </v-col>
            </v-row>

            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ displayName }}</h1>
                <p class="text-caption text-center">Webauthz</p>
                </v-col>
            </v-row>

            <v-row justify="center" class="py-5" v-if="!isReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title>Processing...</v-toolbar-title>
                        </v-toolbar>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="px-5 py-5" v-if="isGranted">
                <v-alert dense type="success" border="left">
                    <template #prepend>
                        <font-awesome-icon fixed-width icon="check" class="white--text text-body-1"/>
                    </template>
                    <span class="ml-2 text-body-1">The access request was granted</span>
                </v-alert>
            </v-row>
            <v-row justify="center" class="px-5 py-5" v-if="isDenied">
                <v-alert dense type="info" border="left">
                    <template #prepend>
                        <font-awesome-icon fixed-width icon="info-circle" class="white--text text-body-1"/>
                    </template>
                    <span class="ml-2 text-body-1">The access request was denied</span>
                </v-alert>
            </v-row>
            <v-row justify="center" class="px-5 py-5" v-if="isBadRequest">
                <v-alert dense type="error" border="left">
                    <template #prepend>
                        <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                    </template>
                    <span class="ml-2 text-body-1">We cannot process this request</span>
                </v-alert>
            </v-row>

            <v-row justify="center" class="py-5 px-5" v-if="isReady && nextRoute">
                <!-- TODO: should be based on nextRoute in client_state -->
                <!-- <router-link to="/admin">Return to LoginShield setup</router-link> -->
                <v-btn text color="blue" tile :to="nextRoute">Continue</v-btn>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        product: null,
        isReady: false,
        isBadRequest: false,
        isDenied: false,
        isGranted: false,
        error: null,
        nextRoute: null,
    }),

    computed: {
        displayName() {
            return this.product?.display_name ?? this.product?.alias ?? 'Unknown product';
        },
    },

    methods: {
        async loadProduct() {
            try {
                this.$store.commit('loading', { loadProduct: true });
                const result = await this.$client.main().product.get({ id: this.$route.params.productId });
                console.log(`product/webauthz/grant.vue loadProduct result: ${JSON.stringify(result)}`);
                if (result) {
                    this.product = result;
                }
            } catch (err) {
                console.error('product/webauthz/grant.vue loadProduct failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load product', message });
            } finally {
                this.$store.commit('loading', { loadProduct: false });
            }
        },
        async checkWebauthzGrant() {
            try {
                /* eslint-disable camelcase */
                this.isReady = false;
                const {
                    client_id,
                    client_state,
                    status,
                    grant_token,
                } = this.$route.query;

                console.error(`checkWebauthzGrant: client_id ${client_id} client_state ${client_state} status ${status} grant_token ${grant_token}`);
                if (!client_id || !client_state) {
                    this.error = true;
                    this.isBadRequest = true;
                    return;
                }

                const { status: finalStatus, nextRoute, error } = await this.$client.main().webauthz.finishWebauthzRequest(
                    null, // { id: this.$route.params.productId },
                    {
                        client_id,
                        client_state,
                        status,
                        grant_token,
                    },
                );

                if (nextRoute) {
                    this.nextRoute = nextRoute;
                }

                if (error) {
                    console.log(`checkWebauthzGrant error ${error}`);
                    this.isBadRequest = true;
                    return;
                }

                if (finalStatus === 'denied') {
                    this.isDenied = true;
                    return;
                }

                if (finalStatus === 'granted' && nextRoute) {
                    console.log('checkWebauthzGrant completed successfully, redirecting');
                    this.$router.push(nextRoute);
                    return;
                }

                if (finalStatus === 'granted') {
                    console.log('checkWebauthzGrant completed successfully, but nextRoute is not specified');
                    this.isGranted = true;
                    return;
                }

                console.log(`checkWebauthzGrant final status ${finalStatus}`);

                this.isBadRequest = true;

                /* eslint-enable camelcase */
            } catch (err) {
                console.error('checkIsEnabled failed', err);
                this.isBadRequest = true;
            } finally {
                this.isReady = true;
            }
        },
    },

    mounted() {
        // this.loadProduct();
        this.checkWebauthzGrant();
    },
};
</script>
