<template>
    <v-row no-gutters class="px-5">
        <v-col cols="12">
        <v-row justify="center" class="py-5">
            <h1 class="text-h4 font-weight-light">Create Account</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="12" md="12" lg="10" xl="10" class="pa-0">
                <p>Coming soon...</p>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        list: [],
        error: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    methods: {
        init() {
            if (this.isAuthenticated) {
                // TODO: is there anything to initialize?
            } else {
                // TODO: redirect to signup page? login page? this page should NOT double as a sign-up page
            }
        },
    },
    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    mounted() {
        if (this.isAuthenticatedReady) {
            this.init();
        }
    },
};
</script>
