import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser, faEnvelope, faPhone, faCheck, faFile,
    faCheckSquare, faPlus, faTrash, faSave, faCamera,
    faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
    faBriefcase, faCompress, faDesktop,
    faShieldAlt as faShieldAltSolid,
    faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faCloudDownloadAlt, faUnlock,
    faDownload as faDownloadSolid,
    faFileInvoiceDollar as faFileInvoiceDollarSolid,
    faTshirt as faTshirtSolid,
    faBox as faBoxSolid,
    faTicketAlt as faTicketAltSolid,
    faEye as faEyeSolid,
    faCog as faCogSolid,
    faChartLine as faChartLineSolid,
    faFolder as faFolderSolid,
    faFolderOpen as faFolderOpenSolid,
    faChevronLeft as faChevronLeftSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft,
    faEllipsisV as faEllipsisVSolid,
    faCloudUploadAlt as faCloudUploadAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion as faQuestionSolid,
    faCircle as faCircleSolid,
    faCheckCircle as faCheckCircleSolid,
    faIdBadge as faIdBadgeSolid,
    faLock as faLockSolid,
    // faStar as faStarSolid,
    // faStarHalf as faStarHalfSolid,
    faExternalLinkAlt as faExternalLinkAltSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare,
    faCircle as faCircleRegular,
    faCopy as faCopyRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
    // faStar as faStarLight,
    faCopy as faCopyLight,
} from '@fortawesome/pro-light-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import {
    faEllipsisV as faEllipsisVRegular,
    faCode as farCode,
    faExternalLinkAlt as faExternalLinkAltRegular,
    faSyncAlt as faSyncAltRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faQuestionSquare as faQuestionSquareSolid,
    faBoxHeart as faBoxHeartSolid,
} from '@fortawesome/pro-solid-svg-icons';

function setup() {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
    library.add(faUser, faEnvelope, faPhone, faCheck, faFile, faCheckSquare, faSquare,
        faPlus, faTrash, faSave, faCamera, faBriefcase, faCompress, faDesktop, faShieldAltSolid,
        faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
        faHeart, faBuilding, faUserSecret, faFingerprint, faCode, faBars, faUserCircle,
        faPencilAlt, faSearch, faEdit, faInfoCircle,
        faCloudDownloadAlt, faUnlock,
        faDownloadSolid,
        faFileInvoiceDollarSolid,
        faTshirtSolid,
        faBoxSolid,
        faTicketAltSolid,
        faEyeSolid,
        faCogSolid,
        faChartLineSolid, faFolderSolid,
        faFolderOpenSolid,
        faChevronLeftSolid,
        faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
        faMinusSquare, faPaperclip, faArrowLeft,
        faEllipsisVSolid, faCloudUploadAltSolid, faExclamationTriangleSolid,
        faQuestionCircleSolid,
        faQuestionSquareSolid,
        faBoxHeartSolid,
        faQuestionSolid,
        faCircleSolid, faCircleRegular,
        faCheckCircleSolid,
        faIdBadgeSolid,
        faLockSolid,
        faExternalLinkAltSolid,
        faExternalLinkAltRegular,
        faSyncAltRegular,
        faCopyRegular,
        faCopyLight,
        // faStarLight, faStarSolid,
        // faStarHalfSolid,
        faEllipsisVRegular,
        farCode);
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
